html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Blizzard color */
#blizzard {
  color: #00aeff;
}

/* Class Colors */
#deathknight {
  color: #c41e3b;
}

#demonhunter {
  color: #a330c9
}

#druid {
  color: #ff7c0a
}

#evoker {
  color: #33937f;
}

#hunter {
  color: #aad372;
}

#mage {
  color: #68ccef;
}

#monk {
  color: #00ffba;
}

#paladin {
  color: #f48cba;
}

#priest {
  color: #f0ebe0;
}

#rogue {
  color: #fff468;
}

#shaman {
  color: #2359ff;
}

#warlock {
  color: #9382c9;
}

#warrior {
  color: #c69b6d;
}
