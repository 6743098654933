.App {
  text-align: center;
  /* background: #212121; fallback for old browsers */
  /* background: -webkit-linear-gradient(to bottom, #4A00E0, #8E2DE2);  Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to bottom, #4A00E0, #8E2DE2); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 100vh;
  background: #121212;
  background: url('./images/bg-factionless-gray-map.jpg') no-repeat fixed center;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiTooltip-tooltip {
  font-size: 0.75rem !important;
  background-color: #252525 !important;
}

/* .MuiPaper-root {
  background-image: none !important;
} */

#dark-glass {
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

#CardArea {
  color: #000000;
}

#CardArea:hover>span {
  opacity: 0.85;
}

#CardArea:hover #CardDesc {
  visibility: visible;
}

#CardDesc {
  visibility: hidden;
  z-index: 4;
  text-align: center;
  text-shadow: 2px 2px 2px #000000;
}

.MuiCardHeader-content {
  z-index: 1;
}

#mem-alliance {
  /* mask-image: linear-gradient(to top, transparent 20%, black 40%); */
}

#mem-horde {
  /* mask-image: linear-gradient(to top, transparent 20%, black 40%); */
}

/* Twitter Embeds */
.twitter-timeline {
  border: 1px solid #121212 !important;
  border-radius: 4px;
  box-shadow: 5px 5px 4px #000;
  background: #121212;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #772ce8 #121212;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #121212;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: #772ce8;
  border-radius: 10px;
  border: 0px none #121212;
}

/* Wowhead Tooltip Mods */
.wowhead-tooltip td,
.wowhead-tooltip th {
  background: #1a1a1acc;
}

.wowhead-tooltip table,
.wowhead-tooltip td,
.wowhead-tooltip th,
.wowhead-tooltip tbody {
  border: none !important;
}

.wowhead-tooltip {
  border: 1px solid black !important;
  box-shadow: 2px 2px 8px #000000 !important;
}

/* .wowhead-tooltip p {
  left: 275px !important;
  top: 2px !important;
} */